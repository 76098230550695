import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "../../../store/stores/ApplicationState";
import { useEffect } from "react";
import { LibraryActions } from "../../../store/stores/library/LibraryStore.Actions";
import React from "react";
import { encodeNameForURI } from "../../../utils/StringUtilities";
import { Link } from "react-router-dom";

export const ScenarioResults = () => {
    const dispatch = useDispatch();
    const { scenarios, allScenariosLoaded } = useSelector((state: ApplicationState) => state.library);

    useEffect(() => {
        if (!allScenariosLoaded) {
            dispatch(LibraryActions.loadScenarios());
        }
    }, [allScenariosLoaded]);

    return (
        <section className="search-results">
            <table className="themed-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Tags</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {scenarios.map(scenario => (
                        <tr>
                            <td>{scenario.name}</td>
                            <td>{scenario.tags.join(', ')}</td>
                            <td><Link to={`${encodeNameForURI(scenario.name)}`}>View</Link></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    )
}