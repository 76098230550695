import { Route, Routes } from "react-router";
import React, { useEffect } from "react";
import { EnemyViewer } from "./EnemyViewer";
import { EnemyResults } from "./EnemyResults";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store/stores/ApplicationState";
import { LibraryActions } from "../../../store/stores/library/LibraryStore.Actions";

export const EnemiesLibrary = () => {
    const dispatch = useDispatch();
    
    const { allEnemiesLoaded } = useSelector((state: ApplicationState) => state.library);
    useEffect(() => {
        if (!allEnemiesLoaded) {
            dispatch(LibraryActions.loadEnemies());
        }
    }, [dispatch, allEnemiesLoaded]);
    return (
        <Routes>
            <Route path=":name" element={<EnemyViewer />} />
            <Route path="*" element={<EnemyResults />} />
        </Routes>
    )
}
