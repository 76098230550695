import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DieFace } from "../../../../../entities/rolls/Roll";
import { ApplicationState } from "../../../../../store/stores/ApplicationState";
import { SheetActions } from "../../../../../store/stores/characters/sheet/actions/Sheet.Actions";
import { EditableDie } from "../../../../inputs/EditableDie";
import { LocalAdventure } from "../../../../../entities/adventures/Adventure";

type Props = {
    adventure: LocalAdventure | null;
}

export const CommunalRolls = (props: Props) => {
    const { adventure } = props;
    const dispatch = useDispatch();
    const sheetCommunalDice = useSelector((app: ApplicationState) => app.sheet.communalDice);
    const onChange = (index: number, value: DieFace) => {
        if (adventure != null) {
            return;
        }
        sheetCommunalDice.splice(index, 1, value);
        dispatch(SheetActions.setCommunalRolls(sheetCommunalDice));
    }
    const onChangeFirst = (value: DieFace) => onChange(0, value);
    const onChangeSecond = (value: DieFace) => onChange(1, value);

    const diceToUse = adventure != null
        ? adventure.communalDice
        : sheetCommunalDice;
    return (
        <div className="communal-rolls">
            <label className="standout">Communal Dice</label>
            <EditableDie faceValue={diceToUse[0]} onChange={onChangeFirst} disabled={adventure != null} rolling={adventure?.rolling} />
            <EditableDie faceValue={diceToUse[1]} onChange={onChangeSecond} disabled={adventure != null} rolling={adventure?.rolling} />
        </div>
    );
}