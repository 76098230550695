import { AnyAction, configureStore, Dispatch } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { AccountApiServerConfig } from '../apis/account/config/AccountApiServerConfig';
import { AdventureHub } from '../apis/account/Hub.Adventure';
import { createRootReducer } from './RootReducer';
import { ApplicationState } from './stores/ApplicationState';

function configureAppStore(preloadedState: ApplicationState) {
    const reducer = createRootReducer();


    const middleware = [thunk, ...AdventureHub.CreateMiddleware()];

    const store = configureStore({
        reducer,
        middleware,
        preloadedState,
    });

    const adventureHub = new AdventureHub(store, AccountApiServerConfig.AdventureHubConfig);
    setupHubs(adventureHub, store.dispatch);
    return store;
}

export type StoreDispatch = ReturnType<typeof configureAppStore>['dispatch'];

export type StoreGetState = ReturnType<typeof configureAppStore>['getState'];

export default configureAppStore;

function setupHubs(adventureHub: AdventureHub, dispatch: Dispatch<AnyAction>) {
    dispatch({ type: 'API_SET_HUB_ADVENTURE', adventureHub });
}