import React, { useEffect, useState } from "react"
import { ThemedButton } from "../../inputs/buttons/ThemedButton"
import { BreakdownWindow } from "../../theming/breakdown-window";
import { useDispatch, useSelector } from "react-redux";
import { AdventureActions } from "../../../store/stores/adventures/AdventureStore.Actions";
import { ApplicationState } from "../../../store/stores/ApplicationState";
import { Loader } from "../../theming/loader/Loader";
import { Adventure } from "../../../entities/adventures/Adventure";
import { UiConfig } from "../../../config/UIConfig";
import { AdventureEventComponent } from "./AdventureEventComponent";
import { Notice } from "../../theming/Notice";

type Props = {
    adventure: Adventure
}

const DefaultTakeEventCount = 30;

export const AdventureLog = (props: Props) => {
    const [viewingLog, setViewingLog] = useState(false);
    return (
        <div className="adventure-log">
            <ThemedButton onClick={() => setViewingLog(true)}>View Game Log</ThemedButton>
            {viewingLog && <AdventureEventsView adventure={props.adventure} close={() => setViewingLog(false)} />}
        </div>
    );
}

type ViewProps = Props & {
    close: () => unknown;
}

const AdventureEventsView = (props: ViewProps) => {
    const { adventure, close } = props;
    const { adventure: adventureState, player: userState, character: characterState } = useSelector((app: ApplicationState) => app);
    const events = adventureState.eventsByAdventureId[adventure.id] ?? [];
    const {initialLoadPerformed, loading, done} = adventureState.loadingEvents[adventure.id] ?? {loading: false, done: false};

    const [timeout, setLocalTimeout] = useState<NodeJS.Timeout>(setTimeout(() => {}, 0));

    const dispatch = useDispatch();
    useEffect(() => {
        const noEventsLoaded = !events.any() || !initialLoadPerformed;
        if (noEventsLoaded && !loading && !done) {
            dispatch(AdventureActions.getAdventureEvents(adventure.id, events.length, DefaultTakeEventCount));
        }
    }, [adventure.id, events, initialLoadPerformed, loading, done, dispatch]);


    const onScroll = (e: React.UIEvent) => {
        clearTimeout(timeout);
        const localTimeout = setTimeout(() => {
            const element = e.target as EventTarget & Element;
            const scrollOffset = element.scrollHeight - element.scrollTop;
            if (scrollOffset  >= UiConfig.infiniteScrollThreshold && !loading && !done) {
                dispatch(AdventureActions.getAdventureEvents(adventure.id, events.length, DefaultTakeEventCount));
            }
        }, 250);
        setLocalTimeout(localTimeout);
    }

    const entities = useSelector((app: ApplicationState) => app.library);

    if (adventure == null) {
        throw new Error("No adventure found.");
    }

    if (userState.me == null) {
        throw new Error("Unauthenticated adventure log access.");
    }
    
    const players = [userState.me, ...userState.players];
    const characters = [...characterState.characters, ...characterState.otherPlayerCharacters];

    return (
        <BreakdownWindow className="slim" onScroll={onScroll} close={close} heading={<h1>Adventure Log</h1>}>
            <div>
                {loading && !events.any() && <Loader>Loading Events</Loader>}
                {events.map(event => <Notice key={event.id}>
                    <AdventureEventComponent adventure={adventure} event={event} players={players} characters={characters} entities={entities} includeTimestamp />
                </Notice>)}
                {loading && events.any() && <Loader />}
            </div>
        </BreakdownWindow>
    )
}