import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { ApplicationState } from "../../../store/stores/ApplicationState";
import { useEffect } from "react";
import { LibraryActions } from "../../../store/stores/library/LibraryStore.Actions";
import { decodeNameFromURI } from "../../../utils/StringUtilities";
import { SortedSet } from "../../../entities/data-structures/SortedSet";
import React from "react";
import { Breadcrumbs } from "../navigation/BreadCrumbs";
import { LibraryRootRoute, ScenariosRoute } from "../navigation/LibraryRoutes";
import { MarkdownContainer } from "../../theming/MarkdownContainer";
import { Loader } from "../../theming/loader/Loader";

export const ScenarioViewer = () => {
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch();
    const { scenarios, allScenariosLoaded } = useSelector((state: ApplicationState) => state.library);

    useEffect(() => {
        if (!allScenariosLoaded) {
            dispatch(LibraryActions.loadScenarios());
        }
    }, [dispatch, allScenariosLoaded]);

    if (!allScenariosLoaded) {
        return <Loader />;
    }

    let name = params["name"] as string | undefined;
    if (name == undefined) {
        throw new Error("No scenario name provided.");
    }
    name = decodeNameFromURI(name);

    const set = new SortedSet(scenarios);
    const scenario = set.getByName(name);
    if (scenario == undefined) {
        throw new Error("No scenario found.");
    }

    return (
        <>
            <Breadcrumbs crumbs={[LibraryRootRoute, ScenariosRoute, {path: location.pathname, name}]} />
            <div>
                <h1>{scenario.name}</h1>
                <MarkdownContainer>{scenario.contents}</MarkdownContainer>
            </div>
        </>
    )
}